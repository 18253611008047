import React, { useEffect, useRef, useState } from 'react'
import '../../landingAssets/css/custom-animated.css';
import '../../landingAssets/css/default.css';
import '../../landingAssets/css/font-awesome.min.css';
import '../../landingAssets/css/magnific-popup.css';
import '../../landingAssets/css/magnific.dark.css';
import '../../landingAssets/css/magnific.rtl.css';
import '../../landingAssets/css/main.css';
import '../../landingAssets/css/style.css';
import "./user-dashboard.min.css"
import Loader from '../landing/loder/Loader.jsx';
import FooterHomeOne from '../landing/Home/FooterHomeOne.jsx';
import { Modal, OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import Select from 'react-select'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import AvatarEditor from 'react-avatar-editor'
import { useSelector } from 'react-redux';
import { getCompanyProfile, updateCompanyProfile, getAllPlan, getAllPaymentHistory, getAllCompanyModules, createCompanyUser, getAllCompanyUsers, editCompanyUser, deleteCompanyUser } from '../../services/User/api.js';
import CartPage from '../LandingCart/CartPage.js';
import { logOut } from '../../services/User/api';
import { compareAsc, format } from 'date-fns';
import axios from 'axios';
import Swal from 'sweetalert2';
import { parseISO, differenceInMonths, differenceInYears } from 'date-fns';
import config from '../../services/utils/config.json';


const BASE_URL = config['PUBLIC_URL'];



function UserDashboardIndex() {
  const [purchaseData, setPurchaseData] = useState([]);
  const [planData, setPlanData] = useState([]);
  const planDetails = useSelector((state) => state.selectedPlan);
  const [planIds, setplanIds] = useState([]);
  const [modules, setModules] = useState([]);
  const token = localStorage.getItem('companyToken');
  const skipOrGetStart = useSelector((state) => state.skipGet);
  const [companyMeta, setCompanyMeta] = useState({});
  const [purchasedModules, setPurchasedModules] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [companyUserList, setCompanyUserList] = useState([]);
  const [companyUser, setCompanyUser] = useState({
    name: '',
    email: '',
    password: '',
    contact_no: 0,
    whats_app_number: 0,
    profile_image: ""
  });
  const [updateCompanyUser, setUpdateCompanyUser] = useState({});
  const [userDetails, setUserDetails] = useState({
    company_name: '',
    contact_no: '',
    whats_app_number: '',
    total_employees: '',
    turn_over: '',
    location: '',
    logo: null,
  });
  const [userWithModule, setUserWithModule] = useState([]);
  const [allowEdit, setAllowEdit] = useState('');
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    password: '',
    contact_no: '',
    whats_app_number: '',
  });

  const validateUserInfo = () => {
    let isValid = true;
    let newErrors = { ...errors };

    // Name validation
    if (!companyUser.name) {
      newErrors.name = 'Name is required';
      isValid = false;
    } else {
      newErrors.name = '';
    }


    // Email validation
    if (!companyUser.email) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(companyUser.email)) {
      newErrors.email = 'Email is invalid';
      isValid = false;
    } else {
      newErrors.email = '';
    }

    // Password validation
    if (!companyUser.password) {
      newErrors.password = 'Password is required';
      isValid = false;
    } else if (companyUser.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters long';
      isValid = false;
    } else if (!/[A-Z]/.test(companyUser.password)) {
      newErrors.password = 'Password must contain at least one uppercase letter';
      isValid = false;
    } else if (!/[0-9]/.test(companyUser.password)) {
      newErrors.password = 'Password must contain at least one number';
      isValid = false;
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(companyUser.password)) {
      newErrors.password = 'Password must contain at least one special character';
      isValid = false;
    } else {
      newErrors.password = '';
    }

    // Contact number validation (generic for multiple countries with min 12 digits)
    if (!companyUser.contact_no) {
      newErrors.contact_no = 'Contact number is required';
      isValid = false;
    } else if (!/^\+?[1-9]\d{11,14}$/.test(companyUser.contact_no)) {
      // This regex matches phone numbers with an optional '+' sign followed by a country code and phone number of 12 to 15 digits
      newErrors.contact_no = 'Contact number is invalid';
      isValid = false;
    } else {
      newErrors.contact_no = '';
    }

    // WhatsApp number validation (generic for multiple countries with min 12 digits)
    if (!companyUser.whats_app_number) {
      newErrors.whats_app_number = 'WhatsApp number is required';
      isValid = false;
    } else if (!/^\+?[1-9]\d{11,14}$/.test(companyUser.whats_app_number)) {
      // Same regex as contact number for WhatsApp number
      newErrors.whats_app_number = 'WhatsApp number is invalid';
      isValid = false;
    } else {
      newErrors.whats_app_number = '';
    }

    // Update errors state (assuming you have a setErrors function to update the state)
    setErrors(newErrors);
    return isValid;

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails(() => ({
      ...userDetails,
      [name]: value,
    }));
  };

  const handleCompanyUserChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCompanyUser((prev) => ({
      ...companyUser,
      [name]: value
    }));
  };

  const handleUpdateChange = async (e) => {
    setUpdateCompanyUser(() => ({
      ...updateCompanyUser,
      [e.target.name]: e.target.value
    }))
  };

  const populateUpdateData = async (user) => {
    setUpdateCompanyUser(user);
    setUserWithModule(user.User_With_Module.map((mod) => ({ value: mod.module.id, label: mod.module.module_name })));
  };

  // const handleCompanyUserRole = async (role) => {
  //   const value = role;
  //   setCompanyUser((prev) => ({
  //     ...companyUser,
  //     ['role']: value
  //   }));
  // };

  const handleCompanyUserPermission = (selected) => {
    setSelectedModules(selected);
  };

  const handleCompanyUserPermissionUpdate = (selected) => {
    setUserWithModule(selected);
  };

  const handleCompanyUserContact = async (contact) => {
    const contact_no = contact.phone;
    setCompanyUser((prev) => ({
      ...companyUser,
      ['contact_no']: contact_no
    }));
  };

  const handleUpdateCompanyUserContact = async (contact) => {
    const contact_no = contact.phone;
    setUpdateCompanyUser(() => ({
      ...updateCompanyUser,
      ['contact_no']: contact_no
    }));
  }

  const handleCompanyUserWhatsApp = async (contact) => {
    const whats_app = contact.phone;
    setCompanyUser((prev) => ({
      ...companyUser,
      ['whats_app_number']: whats_app
    }));
  };

  const handleUpdateCompanyUserWhatsApp = async (contact) => {
    const whats_app = contact.phone;
    setUpdateCompanyUser(() => ({
      ...updateCompanyUser,
      ['whats_app_number']: whats_app
    }));
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('company_name', userDetails.company_name);
      formData.append('contact_no', userDetails.contact_no);
      formData.append('whats_app_number', userDetails.whats_app_number);
      formData.append('total_employees', userDetails.total_employees);
      formData.append('turn_over', userDetails.turn_over);
      formData.append('location', userDetails.location);
      formData.append('owner_name', userDetails.owner_name);
      if (typeof image === 'object') {
        formData.append('logo', image);
      } else {
        formData.append('logo', userDetails.logo);
      }

      await updateCompanyProfile(token, formData);
      const response = await getCompanyProfile(token);
      setShowImage(`${BASE_URL}/uploads/${response.data.data.logo}`);
      setUserDetails(response.data.data);
      Swal.fire('Profile updated successfully');
      setAllowEdit('');
      console.log('Profile updated successfully');

      //setUserDetails(res.data);
    } catch (error) {
      console.error('Failed to update profile:', error.message);
    }
  };


  //tab active
  const [activeTab, setActiveTab] = useState('accountinformation');

  const handleUsersGoClick = () => {
    setActiveTab('users');
  };

  const handlePaymentHistoryGoClick = () => {
    setActiveTab('paymentHistory');
  };

  const handlePlanDetailsGoClick = () => {
    setActiveTab('plan');
  };

  useEffect(() => {
    const tabContent = document.getElementById(activeTab);
    if (tabContent) {
      tabContent.scrollTop = 0;
    }
  }, [activeTab]);

  useEffect(() => {
    const fetchCompanyProfile = async () => {
      try {
        const response = await getCompanyProfile(token);
        setShowImage(`${BASE_URL}/uploads/${response.data.data.logo}`);
        setUserDetails(response.data.data);
        setCompanyMeta(response.data.meta);
        let planId = response.data.data.User_with_Plan.map((item) => item.plan.id);
        setplanIds(planId);
        const plansData = await getAllPlan();
        let selectedPlans = [];
        for (let i = 0; i < plansData.data.length; i++) {
          if (planIds.includes(plansData.data[i].id)) {
            selectedPlans.push(plansData.data[i]);
          }
        }

        setModules(selectedPlans);
        let companyMoudlesList = await getAllCompanyModules(token);
        companyMoudlesList = companyMoudlesList.data.data.flatMap((modData) => {
          return modData.plan.modules.map(element => {
            return { value: element.module.id, label: element.module.module_name };
          });
        });
        const paymentHistoryResponse = await getAllPaymentHistory(token);
        setPurchasedModules(companyMoudlesList);
        setPurchaseData(paymentHistoryResponse.data);
        const allCompanyUsers = await getAllCompanyUsers(token);
        setCompanyUserList(allCompanyUsers.data.data);
      } catch (error) {
        console.error('Failed to fetch order history:', error.message);
      }
    };

    if (token) {
      fetchCompanyProfile();
    }
  }, [token]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateUserInfo()) {
      const assignedModules = selectedModules.map((mod) => mod.value);
      try {
        const formData = new FormData();
        let [planId] = planIds;
        formData.append('name', companyUser.name);
        formData.append('email', companyUser.email);
        formData.append('password', companyUser.password);
        formData.append('contact_no', companyUser.contact_no);
        formData.append('whats_app_number', companyUser.whats_app_number);
        formData.append('module_ids', assignedModules);
        formData.append('profile_image', selectedFile || "");
        await createCompanyUser(formData, token);
        const allCompanyUsers = await getAllCompanyUsers(token);
        setCompanyUserList(allCompanyUsers.data.data);
        createUserModalClose();
        setCompanyUser({});

      } catch (e) {
        console.error('Error:', e.message);
      }
    }
  };

  const handleUserUpdate = async (e) => {
    e.preventDefault();
    const assignedModules = userWithModule.map((mod) => mod.value);
    const formData = new FormData();
    let [planId] = planIds;
    console.log(updateCompanyUser, 'updateCompanyUser');
    formData.append('id', updateCompanyUser.id);
    formData.append('name', updateCompanyUser.name);
    formData.append('email', updateCompanyUser.email);
    formData.append('password', updateCompanyUser.password || " ");
    formData.append('contact_no', updateCompanyUser.contact_no);
    formData.append('whats_app_number', updateCompanyUser.whats_app_number);
    formData.append('module_ids', assignedModules);
    formData.append('profile_image', selectedFile || " ");
    const response = await editCompanyUser(formData, token);
    const allCompanyUsers = await getAllCompanyUsers(token);
    setCompanyUserList(allCompanyUsers.data.data);
    editUserModalClose();
    setUpdateCompanyUser({});

    console.log(response, 'updateresponse');
    // if(respose)
  };

  const handleUserDelete = async (e) => {
    e.preventDefault();
    const response = await deleteCompanyUser(updateCompanyUser.id, token);
    const allCompanyUsers = await getAllCompanyUsers(token);
    setCompanyUserList(allCompanyUsers.data.data);
    deleteModalClose();

  }

  //profile image
  const [image, setImage] = useState(null);
  const [showImage, setShowImage] = useState('');
  const [scale, setScale] = useState(1.0);
  const editorRef = useRef(null);

  const handleScaleChange = (event) => {
    const scale = parseFloat(event.target.value);
    setScale(scale);
  };

  const handleSave = () => {
    // const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
    // const imageUrl = showImage; // Replace with your image URL

    // fetch(proxyUrl + imageUrl)
    //   .then(response => response.blob())
    //   .then(blob => {
    //     const file = new File([blob], 'image.jpg', { type: blob.type });
    //     console.log(file.name, 'file');
    //     // Use the file in your AvatarEditor or any other process
    //   })
    //   .catch(error => console.error('Error fetching image:', error));
    if (editorRef.current) {
      try {
        const canvas = editorRef.current.getImage();
        const dataURL = canvas.toDataURL();
        const file = dataURLtoFile(dataURL, 'avatar.png');
        setImage(file);
        setShowImage(URL.createObjectURL(file));
        handleProfileImageModalClose();
        // console.log(file,'file');
      } catch (e) {
        console.error('Error exporting canvas:', e);
        handleProfileImageModalClose();
      }
    }
  };

  function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  //profile image end
  //role
  const permissionFor = [
    { value: 'crm', label: 'CRM' },
    { value: 'ims', label: 'Inventory Management' },
    { value: 'bms', label: 'BMS' },
    { value: 'prdms', label: 'Production Management' },
    { value: 'prjms', label: 'Project Management' },
    { value: 'hr', label: 'HR' },
    { value: 'purms', label: 'Purchase Management' }
  ]
  //role
  const roleType = [
    { value: 'select', label: '-Select-' },
    { value: 'owner', label: 'Owner' },
    { value: 'member', label: 'Member' }
  ]
  //profile image modal
  const [profileImageModalShow, setProfileImageModalShow] = useState(false);
  const handleProfileImageModalClose = () => setProfileImageModalShow(false);
  const handleProfileImageModalShow = () => setProfileImageModalShow(true);
  //edit user modal
  const [editUserShow, setEditUserShow] = useState(false);
  const editUserModalClose = () => setEditUserShow(false);
  const editUserModalShow = () => setEditUserShow(true);
  //view user modal
  const [viewUserShow, setViewUserShow] = useState(false);
  const viewUserModalClose = () => setViewUserShow(false);
  const viewUserModalShow = () => setViewUserShow(false);
  //Create user modal
  const [createUserShow, setCreateUserShow] = useState(false);
  const createUserModalClose = () => setCreateUserShow(false);
  const createUserModalShow = () => setCreateUserShow(true);
  //delete modal
  const [deleteShow, setDeleteShow] = useState(false);
  const deleteModalClose = () => setDeleteShow(false);
  const deleteModalShow = () => setDeleteShow(true);
  // Storage modal
  const [showStorageModal, setShowStorageModal] = useState(false);
  const handleStorageModalClose = () => setShowStorageModal(false);
  const handleStorageModalShow = () => setShowStorageModal(true);

  // Plan Modal
  const [showPlanModal, setShowPlanModal] = useState(false);
  const handlePlanModalClose = () => setShowPlanModal(false);
  const handlePlanModalShow = () => setShowPlanModal(true);
  //Loader
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  });
  // let planIds = userDetails.User_with_Plan.map((item)=>item.plan.id);
  // console.log(planIds,"planid");


  function determinePlanLength(createdAtISO, planExpirationDateISO) {
    console.log(createdAtISO, planExpirationDateISO, 'createdAtISO, planExpirationDateISO')
    const createdAt = parseISO(createdAtISO);
    const planExpirationDate = parseISO(planExpirationDateISO);

    // Calculate the difference in months and years
    const diffInMonths = differenceInMonths(planExpirationDate, createdAt);
    const diffInYears = differenceInYears(planExpirationDate, createdAt);

    let lengthType;
    if (diffInYears >= 1) {
      lengthType = 'Annual';
    } else if (diffInMonths >= 1) {
      lengthType = 'Monthly';
    } else {
      lengthType = 'less than a month';
    }

    return lengthType;
  }

  return (
    <>
      {loading && (
        <div className={`appie-loader ${loading ? 'active' : ''}`}>
          <Loader />
        </div>
      )}

      <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
        <div className='user-account-page'>
          <div>
            <header className="user-account-header-wrap">
              <div className="container">
                <div className="user-account-header-cont">
                  <div className="logo-wrap">
                    <a href='/'><img src={process.env.PUBLIC_URL + 'assets/images/logo-navy.png'} alt="Logo" className="img-fluid brand-name" /></a>
                  </div>
                  <a className="gth-btn-outline d-flex align-items-center" onClick={(e) => { e.preventDefault(); logOut() }} style={{ cursor: 'pointer' }}>
                    Sign Out <i className="fi fi-br-sign-out-alt mt-1 ms-2"></i>
                  </a>
                </div>
              </div>
            </header>
            <div className="user-account-content-wrapper">
              <div className="container-fluid px-0">
                <div>
                  <ul className="nav nav-tabs gth-tabs user-account-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeTab === 'accountinformation' ? 'active' : ''}`}
                        data-bs-toggle="tab"
                        data-bs-target="#accountinformation"
                        type="button"
                        role="tab"
                        aria-selected={activeTab === 'accountinformation'}
                        onClick={() => setActiveTab('accountinformation')}
                      >
                        <i className="fi fi-sr-user"></i> Profile Information
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeTab === 'users' ? 'active' : ''}`}
                        data-bs-toggle="tab"
                        data-bs-target="#users"
                        type="button"
                        role="tab"
                        aria-selected={activeTab === 'users'}
                        onClick={() => setActiveTab('users')}
                      >
                        <i className="fi fi-sr-users"></i> Users List
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeTab === 'plan' ? 'active' : ''}`}
                        data-bs-toggle="tab"
                        data-bs-target="#plan"
                        type="button"
                        role="tab"
                        aria-selected={activeTab === 'plan'}
                        onClick={() => setActiveTab('plan')}
                      >
                        <i className="fi fi-sr-credit-card"></i> Plan Details
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeTab === 'paymentHistory' ? 'active' : ''}`}
                        data-bs-toggle="tab"
                        data-bs-target="#paymentHistory"
                        type="button"
                        role="tab"
                        aria-selected={activeTab === 'paymentHistory'}
                        onClick={() => setActiveTab('paymentHistory')}
                      >
                        <i className="fi fi-ss-receipt"></i> Payment History
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content user-dashboard-tab-content">
                    <div className={`tab-pane ${activeTab === 'accountinformation' ? 'active' : ''}`} id="accountinformation" role="tabpanel">
                      <div className="container pt-5 pb-4">
                        <div className="row justify-content-center">
                          <div className="col-lg-9 col-md-12 mb-4">
                            <div className="card ghostwhite">
                              <div className="card-body">
                                <div className='user-profile-wrap'>
                                  <div className="profile-image-outer">
                                    {/* <img className="profile-image" src={image || process.env.PUBLIC_URL + 'assets/images/user-profile-pictures/profile-user.png'} alt="user" /> */}
                                    <img className="profile-image" width={10} height={10} src={`${showImage}`} alt="user" />
                                    <div className="profile-image-overlay" onClick={() => { setShowImage(`${showImage}`); handleProfileImageModalShow(); }}>
                                      <i class="fi fi-sr-user"></i>
                                      <p>Change Profile Picture</p>
                                    </div>
                                  </div>
                                  <div className="profile-desc">
                                    <div className='form-group'>
                                      <label className='form-label'>Company Name</label>
                                      <div className="editable-field d-flex align-items-center flex-wrap">
                                        <div className="position-relative editable-area">
                                          <button className="profile-edit-btn" onClick={(e) => { setAllowEdit((allowEdit === 'company_name') ? '' : 'company_name') }}>
                                            <i className="fi fi-rr-pencil"></i>
                                          </button>
                                          <input type="text" value={userDetails?.company_name} name='company_name' className="input-field" readOnly={(allowEdit === 'company_name') ? false : true} onChange={(e) => (handleChange(e))}
                                            style={{
                                              padding: '5px',
                                              borderRadius: '5px',
                                              border: (allowEdit === 'company_name') ? '1px solid black' : 'transparent',
                                              background: (allowEdit === 'company_name') ? 'white' : 'transparent'
                                            }} />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='form-group'>
                                      <label className='form-label'>Name</label>
                                      <div className="editable-field d-flex align-items-center flex-wrap">
                                        <div className="position-relative editable-area">
                                          <button className="profile-edit-btn" onClick={(e) => { setAllowEdit((allowEdit === 'owner_name') ? '' : 'owner_name') }}>
                                            <i className="fi fi-rr-pencil"></i>
                                          </button>
                                          <input type="text" value={userDetails?.owner_name} name='owner_name' className="input-field" readOnly={(allowEdit === 'owner_name') ? false : true} onChange={(e) => handleChange(e)}
                                            style={{
                                              padding: '5px',
                                              borderRadius: '5px',
                                              border: (allowEdit === 'owner_name') ? '1px solid black' : 'transparent',
                                              background: (allowEdit === 'owner_name') ? 'white' : 'transparent'
                                            }}
                                          />
                                        </div>
                                        <span className='badge rounded-pill exp-badge-primary-light'>OWNER</span>
                                      </div>
                                    </div>
                                    <div className='form-group'>
                                      <label className='form-label'>Phone</label>
                                      <div className="editable-field">
                                        <div className="position-relative editable-area">
                                          <button className="profile-edit-btn" onClick={(e) => { setAllowEdit((allowEdit === 'contact_no') ? '' : 'contact_no') }}>
                                            <i className="fi fi-rr-pencil"></i>
                                          </button>
                                          <input type="text" value={userDetails?.contact_no} name='contact_no' readOnly={(allowEdit === 'contact_no') ? false : true} onChange={(e) => handleChange(e)} className="input-field"
                                            style={{
                                              padding: '5px',
                                              borderRadius: '5px',
                                              border: (allowEdit === 'contact_no') ? '1px solid black' : 'transparent',
                                              background: (allowEdit === 'contact_no') ? 'white' : 'transparent'
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='form-group'>
                                      <label className='form-label'>WhatsApp</label>
                                      <div className="editable-field">
                                        <div className="position-relative editable-area">
                                          <button className="profile-edit-btn" onClick={(e) => { setAllowEdit((allowEdit === 'whats_app_number') ? '' : 'whats_app_number') }}>
                                            <i className="fi fi-rr-pencil"></i>
                                          </button>
                                          <input type="text" value={userDetails?.whats_app_number} name='whats_app_number' readOnly={(allowEdit === 'whats_app_number') ? false : true} onChange={(e) => handleChange(e)} className="input-field"
                                            style={{
                                              padding: '5px',
                                              borderRadius: '5px',
                                              border: (allowEdit === 'whats_app_number') ? '1px solid black' : 'transparent',
                                              background: (allowEdit === 'whats_app_number') ? 'white' : 'transparent'
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='form-group'>
                                      <label className='form-label'>Total Employees</label>
                                      <div className="editable-field">
                                        <div className="position-relative editable-area">
                                          <button className="profile-edit-btn" onClick={(e) => { setAllowEdit((allowEdit === 'total_employees') ? '' : 'total_employees') }}>
                                            <i className="fi fi-rr-pencil"></i>
                                          </button>
                                          <input type="text" value={userDetails?.total_employees} name='total_employees' readOnly={(allowEdit === 'total_employees') ? false : true} onChange={(e) => handleChange(e)} className="input-field"
                                            style={{
                                              padding: '5px',
                                              borderRadius: '5px',
                                              border: (allowEdit === 'total_employees') ? '1px solid black' : 'transparent',
                                              background: (allowEdit === 'total_employees') ? 'white' : 'transparent'
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='form-group mb-0'>
                                      <label className='form-label'>Turn Over</label>
                                      <div className="editable-field">
                                        <div className="position-relative editable-area">
                                          <button className="profile-edit-btn" onClick={(e) => { setAllowEdit((allowEdit === 'turn_over') ? '' : 'turn_over') }}>
                                            <i className="fi fi-rr-pencil"></i>
                                          </button>
                                          <input type="text" value={userDetails?.turn_over} name='turn_over' readOnly={(allowEdit === 'turn_over') ? false : true} onChange={(e) => handleChange(e)} className="input-field"
                                            style={{
                                              padding: '5px',
                                              borderRadius: '5px',
                                              border: (allowEdit === 'turn_over') ? '1px solid black' : 'transparent',
                                              background: (allowEdit === 'turn_over') ? 'white' : 'transparent'
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='form-group mb-0'>
                                      <label className='form-label'>Location</label>
                                      <div className="editable-field">
                                        <div className="position-relative editable-area">
                                          <button className="profile-edit-btn" onClick={(e) => { setAllowEdit((allowEdit === 'location') ? '' : 'location') }}>
                                            <i className="fi fi-rr-pencil"></i>
                                          </button>
                                          <input type="text" value={userDetails?.location} name='location' readOnly={(allowEdit === 'location') ? false : true} onChange={(e) => handleChange(e)} className="input-field"
                                            style={{
                                              padding: '5px',
                                              borderRadius: '5px',
                                              border: (allowEdit === 'location') ? '1px solid black' : 'transparent',
                                              background: (allowEdit === 'location') ? 'white' : 'transparent'
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <button className="update-btn btn btn-success mt-4" onClick={(e) => { handleUpdateProfile(e) }}>Update Profile</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {
                              userDetails?.User_with_Plan?.length > 0 ? (
                                <>
                                  <h4 className="mt-4 text-center">Go to you workspace</h4>
                                  <div className="mt-3 mb-4">
                                    <div className="module-list-wrap">
                                      {console.log(purchasedModules, 'modules')}
                                      {purchasedModules?.map((item) => (
                                        <a href="#" className="module-checkbox-list-item" key={item?.id}>
                                          <span className="module-icon"><i className="fi fi-ss-department"></i></span>
                                          <span className="module-name">{item?.label}</span>
                                        </a>
                                      )
                                      )}
                                    </div>
                                    {/* <div className="module-list-wrap">
                                      <a href="#" className="module-checkbox-list-item">
                                        <span className="module-icon"><i className="fi fi-ss-department"></i></span>
                                        <span className="module-name">BMS</span>
                                      </a>
                                      <a href="#" className="module-checkbox-list-item">
                                        <span className="module-icon"><i className="fi fi-sr-crm-computer"></i></span>
                                        <span className="module-name">CRM</span>
                                      </a>
                                      <a href="#" className="module-checkbox-list-item">
                                        <span className="module-icon"><i className="fi fi-sr-module"></i></span>
                                        <span className="module-name">Inventory Management</span>
                                      </a>
                                      <a href="#" className="module-checkbox-list-item">
                                        <span className="module-icon"><i className="fi fi-br-gears"></i></span>
                                        <span className="module-name">Production Management</span>
                                      </a>
                                      <a href="#" className="module-checkbox-list-item">
                                        <span className="module-icon"><i className="fi fi-sr-diagram-project"></i></span>
                                        <span className="module-name">Project Management</span>
                                      </a>
                                      <a href="#" className="module-checkbox-list-item">
                                        <span className="module-icon"><i className="fi fi-sr-hr"></i></span>
                                        <span className="module-name">HR</span>
                                      </a>
                                      <a href="#" className="module-checkbox-list-item">
                                        <span className="module-icon"><i className="fi fi-sr-brand"></i></span>
                                        <span className="module-name">Purchase Management</span>
                                      </a>
                                    </div> */}
                                  </div>
                                </>
                              ) : (<>
                                {
                                  planDetails && (
                                    <CartPage skipOrGetStart={skipOrGetStart} planDetails={planDetails} />
                                  )
                                }

                              </>)
                            }

                          </div>
                        </div>
                        {
                          userDetails?.User_with_Plan?.length > 0 ? (
                            userDetails.User_with_Plan.map((planDetail, index) => (
                              <React.Fragment key={index}>
                                <div className='row justify-content-center'>
                                  <div className='col-lg-9 col-md-12 col-sm-12'>
                                    <div className='card shadow-none border'>
                                      <div className='card-header'>
                                        <h5>{planDetail.plan.plan_name}</h5>
                                      </div>
                                      <div className='card-body pb-0'>
                                        <div className="row gx-4 justify-content-center">
                                          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
                                            <div className="card current-plan-card h-100">
                                              <div className="card-body">
                                                <div className="current-plan-card-wrap">
                                                  <span className="badge rounded-pill gth-bg-info">Active Plan</span>
                                                  <h6 className="current-plan-name">{planDetail.plan.plan_name}</h6>
                                                  <p className="expire-text pe-2">Your plan will expire within <span>{`${planDetail.days_left} Days Left.`}</span></p>
                                                  <div className="d-flex justify-content-between mt-3">
                                                    <button className="btn btn-dark btn-sm rounded-4" onClick={handlePlanDetailsGoClick}>View</button>
                                                    <button className="btn btn-success btn-sm rounded-4" onClick={handlePlanModalShow}>Upgrade</button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
                                            <div className="card user-card h-100">
                                              <div className="card-body">
                                                <div className="user-card-wrap">
                                                  <h6>Total Number of Users</h6>
                                                  <div className="d-flex align-items-center mt-2">
                                                    <span className="users-icon">
                                                      <i className="fi fi-rr-users-alt"></i>
                                                    </span>
                                                    <p className="user-number ps-3">{planDetail.plan.user_limit}</p>
                                                  </div>
                                                  <div className="d-flex justify-content-between align-items-center mt-3">
                                                    {companyMeta && <p className="left-number">Left: <span className="gth-text-danger">{Number(planDetail.plan.user_limit) - Number(planDetail.plan.modules[0].user_count)}</span></p>}
                                                    <button id="users-go" className="btn btn-dark btn-sm rounded-4 view-details" onClick={handleUsersGoClick}>View</button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row gx-4 justify-content-center">
                                          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
                                            <div className="card storage-card h-100">
                                              <div className="card-body">
                                                <div className="storage-card-wrap">
                                                  <div className="d-flex justify-content-between">
                                                    <h6>Your Storage</h6>
                                                    <div className="f-w-medium text-primary">25% Left</div>
                                                  </div>
                                                  <p className="mt-2">1.2 GB of 5 GB are used</p>
                                                  <div className="mb-3">
                                                    <ProgressBar animated variant="warning" now={75} />
                                                  </div>
                                                  <div className="text-end">
                                                    <button className="btn btn-success btn-sm rounded-4 view-details" onClick={handleStorageModalShow}>Upgrade</button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
                                            <div className="card payment-history h-100">
                                              <div className="card-body">
                                                <div className="payment-card-wrap">
                                                  <h6>Total Payment</h6>
                                                  <div className="d-flex align-items-center mt-3 amount mb-3">
                                                    <i className="fi fi-br-indian-rupee-sign"></i>
                                                    <p className="amount-number ps-2">{(determinePlanLength(planDetail.created_at, planDetail.plan_expiration_date) === 'Annual') ? planDetail.plan.annual_price : planDetail.plan.monthly_price}</p>
                                                  </div>
                                                  <div className="text-right">
                                                    <button className="btn btn-dark btn-sm rounded-4 view-details" onClick={handlePaymentHistoryGoClick}>View History</button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            ))) : null
                        }

                      </div>
                    </div>
                    <div className={`tab-pane ${activeTab === 'users' ? 'active' : ''}`} id="users" role="tabpanel">
                      <div className="container pt-5 pb-4">
                        <div className="card">
                          <div className="card-header gth-blue-light-bg d-flex justify-content-between align-items-center">
                            <h5 className="gth-modal-title">Users List</h5>
                            <button className="btn btn-success me-0 ms-auto" onClick={createUserModalShow}>Create User</button>
                          </div>
                          <div className="card-body">
                            <div className="fixed-table-wrapper">
                              <table className="table mb-0 table-striped fixedTable-head">
                                <thead>
                                  <tr>
                                    <th>User</th>
                                    <th>Role</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {companyUserList && companyUserList.map((user) => (
                                    <>
                                      <tr>
                                        <td>
                                          <div className="profile-wrap">
                                            <div className="exp-avtar">
                                              <img className="profile-image" width={40} height={40} src={`http://13.127.208.167/uploads/${user?.profile_image}`} alt="user" />
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                              <h5 className="profile-name text-truncate text-nowrap">{(user?.role.role === "Owner") ? 'You' : user?.name}</h5>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <span>{user?.role.role}</span>
                                        </td>
                                        <td>
                                          <span>{user?.name}</span>
                                        </td>
                                        <td>
                                          <span>{user?.contact_no}</span>
                                        </td>
                                        <td>
                                          {(user?.role.role === "Owner") ? null :
                                            <div className="d-flex">
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip>
                                                    View
                                                  </Tooltip>
                                                }
                                              >
                                                <button className="me-1 table-action-btn" onClick={(e) => { setViewUserShow(true); setUpdateCompanyUser(user); }}>
                                                  <i className="fi fi-rs-eye"></i>
                                                </button>
                                              </OverlayTrigger>
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip>
                                                    Edit
                                                  </Tooltip>
                                                }
                                              >
                                                <button className="me-1 table-action-btn" onClick={() => { editUserModalShow(true); populateUpdateData(user); }}>
                                                  <i className="fi fi-rr-pencil"></i>
                                                </button>
                                              </OverlayTrigger>
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip>
                                                    Delete
                                                  </Tooltip>
                                                }
                                              >
                                                <button className="me-1 table-action-btn" onClick={() => { deleteModalShow(); populateUpdateData(user); }}>
                                                  <i className="fi fi-rr-trash text-exp-red"></i>
                                                </button>
                                              </OverlayTrigger>
                                            </div>
                                          }
                                        </td>
                                      </tr>
                                    </>
                                  ))
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`tab-pane ${activeTab === 'plan' ? 'active' : ''}`} id="plan" role="tabpanel">
                      <div className="container pt-5 pb-4">
                        <div className="card">
                          <div className="card-header gth-blue-light-bg d-flex justify-content-between align-items-center">
                            <h5 className="gth-modal-title">Plan Details</h5>
                          </div>
                          <div className="card-body">
                            <div className="fixed-table-wrapper">

                              <table className="table mb-0 table-striped fixedTable-head">
                                <thead>
                                  <tr>
                                    <th><span className="text-nowrap">Plan Name</span></th>
                                    <th><span className="text-nowrap">Start Date</span></th>
                                    <th><span className="text-nowrap">Date of Expiration</span></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {userDetails?.User_with_Plan && userDetails?.User_with_Plan.map((item, index) => (
                                    <tr key={index}>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <h6 className="me-2 text-nowrap">{item?.plan?.plan_name}</h6>
                                          <span className="badge rounded-pill gth-bg-info">Active Plan</span>
                                        </div>
                                      </td>
                                      <td>
                                        {/* Example date format: Assuming plan creation date */}
                                        <span>{format(new Date(item?.created_at), 'dd/MM/yy')}</span>
                                      </td>
                                      <td>
                                        {/* Example expiration date: Assuming 1 year from creation */}
                                        {/* <span>{new Date(new Date(item.created_at).setFullYear(new Date(item.created_at).getFullYear() + 1)).toLocaleDateString()}</span> */}
                                        <span>{format(new Date(item.plan_expiration_date), 'dd/MM/yy')}</span>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>

                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`tab-pane ${activeTab === 'paymentHistory' ? 'active' : ''}`} id="paymentHistory" role="tabpanel">
                      <div className="container pt-5 pb-4">
                        <div className="card">
                          <div className="card-header gth-blue-light-bg d-flex justify-content-between align-items-center">
                            <h5 className="gth-modal-title">Payment History</h5>
                          </div>
                          <div className="card-body">
                            <div className="fixed-table-wrapper">
                              <table className="table mb-0 table-striped fixedTable-head">
                                <thead>
                                  <tr>
                                    <th><span className="text-nowrap">Purchase Plan</span></th>
                                    <th><span className="text-nowrap">Purchase Date</span></th>
                                    <th><span className="text-nowrap">Transaction ID</span></th>
                                    <th><span className="text-nowrap">Amount</span></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {purchaseData.map((item, index) => (
                                    <tr key={index}>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <h6 className="me-2 text-nowrap">{item?.order?.Order_Details[0]?.plan.plan_name}</h6>
                                          <span className="badge rounded-pill gth-bg-info">Active Plan</span>
                                        </div>
                                      </td>
                                      <td>
                                        <span>{format(new Date(item?.order?.created_at), 'dd/MM/yy')}</span>
                                      </td>
                                      <td>
                                        <span>#{item?.transaction_id}</span>
                                      </td>
                                      <td>
                                        <span className="text-nowrap">&#8377; {item.order?.price}.00</span>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div>
            <FooterHomeOne />
          </div>
        </div>

      </div>

      {/* Plan Modal */}
      <Modal show={showPlanModal} onHide={handlePlanModalClose} animation={false} centered backdrop="static" className="fullscreen">
        <Modal.Header closeButton className="gth-blue-light-bg">
          <Modal.Title className="gth-modal-title">Our plans</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <div className="row justify-content-center">
            <div className="col-12 mb-5 text-center">
              <h2>Choose the right plan for your team</h2>
              <h4>Thank you for choosing Growthh.com!</h4>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="pricing-one__single">
                <div className="card-active-plan"><span className="badge rounded-pill gth-bg-info">Active Plan</span></div>
                <label className="custom-radio">
                  <input type="radio" name="selectPlan" />
                  <span className="checkmark" />
                  <div className="pricig-heading">
                    <h6>Free Trial</h6>
                    <div className="price-range">
                      <sup></sup> <span>FREE</span>
                    </div>
                    <p>Get your 14 day free trial</p>
                  </div>
                  <div className="pricig-body">
                    <ul>
                      <li>
                        <i className="fal fa-check" /> Doer Management System
                      </li>
                      <li>
                        <i className="fal fa-check" /> Repeated Work Management
                      </li>
                      <li>
                        <i className="fal fa-check" /> Task Tracker
                      </li>
                      <li>
                        <i className="fal fa-check" /> Bottleneck Management
                      </li>
                      <li>
                        <i className="fal fa-check" /> Workflows (FMS) <br />(Free upto 1 FMS)
                      </li>
                      <li>
                        <i className="fal fa-check" /> Admin & Doer Dashboard
                      </li>
                      <li>
                        <i className="fal fa-check" /> Weekly Productivity Report
                      </li>
                      <li>
                        <i className="fal fa-check" /> Doer Performance Report
                      </li>
                      <li>
                        <i className="fal fa-check" /> Organisation Privilege's
                      </li>
                      <li>
                        <i className="fal fa-check" /> Auto Productivity Score
                      </li>
                      <li>
                        <i className="fal fa-check" /> Auto Notification Feature
                      </li>
                      <li>
                        <i className="fal fa-check" /> Employee Comparision Score
                      </li>
                      <li>
                        <i className="fal fa-check" /> Automated Reminder
                      </li>
                      <li>
                        <i className="fal fa-check" /> Whatsapp Reminder (API#)
                      </li>
                      <li>
                        <i className="fal fa-check" /> 3 Users
                      </li>
                      <li>
                        <i className="fal fa-check" /> Storage Space <br />(Upto 50 MB)*
                      </li>
                    </ul>
                  </div>
                </label>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="pricing-one__single">
                <div className="card-active-plan"><span className="badge rounded-pill gth-bg-success">Upgrade Plan</span></div>
                <label className="custom-radio">
                  <input type="radio" name="selectPlan" />
                  <span className="checkmark" />
                  <div className="pricig-heading business-growth">
                    <h6>Business Growth</h6>
                    <div className="price-range">
                      <sup>&#8377;</sup> <span>1,999</span>
                      <p>/month <mark className='bg-transparent text-danger'>**</mark></p>
                    </div>
                    <p>( Charged every 30 days )</p>
                  </div>
                  <div className="pricing-rebon">
                    <span>Most Popular</span>
                  </div>
                  <div className="pricig-body">
                    <ul>
                      <li>
                        <i className="fal fa-check" /> Doer Management System
                      </li>
                      <li>
                        <i className="fal fa-check" /> Repeated Work Management
                      </li>
                      <li>
                        <i className="fal fa-check" /> Task Tracker
                      </li>
                      <li>
                        <i className="fal fa-check" /> Bottleneck Management
                      </li>
                      <li>
                        <i className="fal fa-check" /> Workflows (FMS) <br />(Upto 3 FMS)
                      </li>
                      <li>
                        <i className="fal fa-check" /> Admin & Doer Dashboard
                      </li>
                      <li>
                        <i className="fal fa-check" /> Weekly Productivity Report
                      </li>
                      <li>
                        <i className="fal fa-check" /> Doer Performance Report
                      </li>
                      <li>
                        <i className="fal fa-check" /> Organisation Privilege's
                      </li>
                      <li>
                        <i className="fal fa-check" /> Auto Productivity Score
                      </li>
                      <li>
                        <i className="fal fa-check" /> Auto Notification Feature
                      </li>
                      <li>
                        <i className="fal fa-check" /> Employee Comparision Score
                      </li>
                      <li>
                        <i className="fal fa-check" /> Automated Reminder
                      </li>
                      <li>
                        <i className="fal fa-check" /> Whatsapp Reminder (API#)
                      </li>
                      <li>
                        <i className="fal fa-check" /> 10 Users
                      </li>
                      <li>
                        <i className="fal fa-check" /> Storage Space <br />(Upto 2 GB)*
                      </li>
                    </ul>
                  </div>
                </label>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="pricing-one__single">
                <div className="card-active-plan"><span className="badge rounded-pill gth-bg-success">Upgrade Plan</span></div>
                <label className="custom-radio">
                  <input type="radio" name="selectPlan" />
                  <span className="checkmark" />
                  <div className="pricig-heading express">
                    <h6>Business Express</h6>
                    <div className="price-range">
                      <sup>&#8377;</sup> <span>4,999</span>
                      <p>/month <mark className='bg-transparent text-danger'>**</mark></p>
                    </div>
                    <p>( Charged every 30 days )</p>
                  </div>
                  <div className="pricig-body">
                    <ul>
                      <li>
                        <i className="fal fa-check" /> Doer Management System
                      </li>
                      <li>
                        <i className="fal fa-check" /> Repeated Work Management
                      </li>
                      <li>
                        <i className="fal fa-check" /> Task Tracker
                      </li>
                      <li>
                        <i className="fal fa-check" /> Bottleneck Management
                      </li>
                      <li>
                        <i className="fal fa-check" /> Workflows (FMS) <br />(Upto 5 FMS)
                      </li>
                      <li>
                        <i className="fal fa-check" /> Admin & Doer Dashboard
                      </li>
                      <li>
                        <i className="fal fa-check" /> Weekly Productivity Report
                      </li>
                      <li>
                        <i className="fal fa-check" /> Doer Performance Report
                      </li>
                      <li>
                        <i className="fal fa-check" /> Organisation Privilege's
                      </li>
                      <li>
                        <i className="fal fa-check" /> Auto Productivity Score
                      </li>
                      <li>
                        <i className="fal fa-check" /> Auto Notification Feature
                      </li>
                      <li>
                        <i className="fal fa-check" /> Employee Comparision Score
                      </li>
                      <li>
                        <i className="fal fa-check" /> Automated Reminder
                      </li>
                      <li>
                        <i className="fal fa-check" /> Whatsapp Reminder (API#)
                      </li>
                      <li>
                        <i className="fal fa-check" /> 30 Users
                      </li>
                      <li>
                        <i className="fal fa-check" /> Storage Space <br />(Upto 5 GB)*
                      </li>
                    </ul>
                  </div>
                </label>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="pricing-one__single">
                <div className="card-active-plan"><span className="badge rounded-pill gth-bg-success">Upgrade Plan</span></div>
                <label className="custom-radio">
                  <input type="radio" name="selectPlan" />
                  <span className="checkmark" />
                  <div className="pricig-heading enterprise">
                    <h6>Enterprise</h6>
                    <div className="price-range">
                      <div className='requirement'>As per Requirement</div>
                    </div>
                    <p>( Charged every 30 days )</p>
                  </div>
                  <div className="pricig-body">
                    <ul>
                      <li>
                        <i className="fal fa-check" /> Doer Management System
                      </li>
                      <li>
                        <i className="fal fa-check" /> Repeated Work Management
                      </li>
                      <li>
                        <i className="fal fa-check" /> Task Tracker
                      </li>
                      <li>
                        <i className="fal fa-check" /> Bottleneck Management
                      </li>
                      <li>
                        <i className="fal fa-check" /> Workflows (FMS) <br />(As per requirement)
                      </li>
                      <li>
                        <i className="fal fa-check" /> Admin & Doer Dashboard
                      </li>
                      <li>
                        <i className="fal fa-check" /> Weekly Productivity Report
                      </li>
                      <li>
                        <i className="fal fa-check" /> Doer Performance Report
                      </li>
                      <li>
                        <i className="fal fa-check" /> Organisation Privilege's
                      </li>
                      <li>
                        <i className="fal fa-check" /> Auto Productivity Score
                      </li>
                      <li>
                        <i className="fal fa-check" /> Auto Notification Feature
                      </li>
                      <li>
                        <i className="fal fa-check" /> Employee Comparision Score
                      </li>
                      <li>
                        <i className="fal fa-check" /> Automated Reminder
                      </li>
                      <li>
                        <i className="fal fa-check" /> Whatsapp Reminder (API#)
                      </li>
                      <li>
                        <i className="fal fa-check" /> Users (As per requirement)*
                      </li>
                      <li>
                        <i className="fal fa-check" /> Storage Space <br />(As per requirement)*
                      </li>
                    </ul>
                  </div>
                </label>
              </div>
            </div>
            <div className="col-12">
              <p className='mb-2 mt-50'>
                Safe & Secure Data Encryption for Advanced Security. All transmissions to and from Growthh.com, including sign-on, are encrypted at 256-bit and sent through TLS 1.2, adhering to the FIPS 140-2 certification standard.
              </p>
              <p className='mb-2'>
                <mark className='bg-transparent text-danger'>**</mark> GST charges are included.
              </p>
              <p className='mb-2'>
                <mark className='bg-transparent text-danger'>#</mark>Whatsapp API cost charge not included.
              </p>
              <p>
                <mark className='bg-transparent text-dark'>*</mark> Storage is limited with plans. For additional storage contact support at <a href="mailto:crm@growthh.com" className="gth-text-primary1">crm@growthh.com</a>
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="gth-btn-primary" onClick={handlePlanModalClose}>
            Continue
          </button>
        </Modal.Footer>
      </Modal>
      {/* Plan Modal End*/}
      {/* Storage Modal*/}
      <Modal show={showStorageModal} onHide={handleStorageModalClose} animation={false} centered backdrop="static" size="xl">
        <Modal.Header closeButton className="gth-blue-light-bg">
          <Modal.Title className="gth-modal-title">Upgrade Storage Plans</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <div className="row justify-content-center">
            <div className="col-12 mb-5 text-center">
              <h2>Choose the right storage plan for your team</h2>
              <h4 className="text-muted">Thank you for choosing Growthh.com!</h4>
              <p className="mt-3">
                Storage upgrades are charged with base plans. The validity of the storage remains as long as there is a base plan subscription. Without a base plan you can not use this storage.
              </p>
            </div>
            <div className="col-12">
              <div className="storage-plan-wrap">
                <div className="storage-plan-item">
                  <label className="custom-radio">
                    <input type="radio" name="selectStoragePlan" />
                    <span className="checkmark" />
                    <div className="storage-heading">
                      <h6>Cloud 2GB</h6>
                      <div className="storage-unit">
                        <i class="fi fi-rr-cloud-back-up me-2"></i><span>2 GB</span>
                      </div>
                      <div className="storage-price">
                        <>&#8377;</> <span>999</span>
                        <p>/month</p>
                      </div>
                    </div>
                  </label>
                </div>
                <div className="storage-plan-item">
                  <label className="custom-radio">
                    <input type="radio" name="selectStoragePlan" />
                    <span className="checkmark" />
                    <div className="storage-heading">
                      <h6>Cloud 5GB</h6>
                      <div className="storage-unit">
                        <i class="fi fi-rr-cloud-back-up me-2"></i><span>5 GB</span>
                      </div>
                      <div className="storage-price">
                        <>&#8377;</> <span>1,999</span>
                        <p>/month</p>
                      </div>
                    </div>
                  </label>
                </div>
                <div className="storage-plan-item">
                  <label className="custom-radio">
                    <input type="radio" name="selectStoragePlan" />
                    <span className="checkmark" />
                    <div className="storage-heading">
                      <h6>Cloud 10GB</h6>
                      <div className="storage-unit">
                        <i class="fi fi-rr-cloud-back-up me-2"></i><span>10 GB</span>
                      </div>
                      <div className="storage-price">
                        <>&#8377;</> <span>3,499</span>
                        <p>/month</p>
                      </div>
                    </div>
                  </label>
                </div>
                <div className="storage-plan-item">
                  <label className="custom-radio">
                    <input type="radio" name="selectStoragePlan" />
                    <span className="checkmark" />
                    <div className="storage-heading">
                      <h6>Cloud 20GB</h6>
                      <div className="storage-unit">
                        <i class="fi fi-rr-cloud-back-up me-2"></i><span>20 GB</span>
                      </div>
                      <div className="storage-price">
                        <>&#8377;</> <span>5,999</span>
                        <p>/month</p>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12">
              <p className='mb-0 mt-40 text-center'>
                Once storage plans are upgraded, it can not be downgraded. For more information kindly contact <a href="mailto:crm@growthh.com">crm@growthh.com</a>
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="gth-btn-primary" onClick={handleStorageModalClose}>
            Continue
          </button>
        </Modal.Footer>
      </Modal>
      {/* Storage Modal End*/}
      {/* Delete modal start */}
      <Modal
        show={deleteShow}
        onHide={deleteModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton className="gth-light-red-bg">
          <Modal.Title className="gth-text-danger">Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-confirm-wrap text-center">
            <div className="delete-confirm-icon mb-3 mt-2">
              <img src={process.env.PUBLIC_URL + 'assets/images/delete-warning.svg'} alt="Warning" className="img-fluid" />
            </div>
            <h4 className="text-muted">Are you sure?</h4>
            <p className="text-muted">
              Do you really want to delete these record? This process cannot be undone.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className='justify-content-center'>
          <button className='btn btn-secondary' onClick={deleteModalClose}>
            Cancel
          </button>
          <button className='btn btn-exp-red' onClick={(e) => { handleUserDelete(e) }}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
      {/* Delete modal end */}
      {/* Create User modal start */}
      <Modal
        show={createUserShow}
        onHide={() => {
          setCompanyUser(
            {
              name: '',
              email: '',
              password: '',
              contact_no: 0,
              whats_app_number: 0,
              profile_image: ""
            }
          ); createUserModalClose();
        }}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton className="gth-blue-light-bg">
          <Modal.Title className="gth-modal-title">Create User</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pb-1'>
          <div className='row'>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">User name</label>
                <input type="text" className="form-control" placeholder="Enter user name" value={companyUser.name} name="name" onChange={(e) => { handleCompanyUserChange(e) }} />
                {errors.name && <div className="text-danger">{errors.name}</div>}

              </div>
            </div>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">password</label>
                <input type="password" className="form-control" placeholder="Enter user password" name="password" onChange={(e) => { handleCompanyUserChange(e) }}
                  value={companyUser.password}
                />
                {errors.password && <div className="text-danger">{errors.password}</div>}

              </div>
            </div>
            {/* <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">Role</label>
                <Select
                  name='role'
                  value={companyUser.role}
                  options={roleType}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#ddddff',
                      primary: '#6161ff',
                    },
                  })}
                  onChange={(selectedOption) => { handleCompanyUserRole(selectedOption) }}
                />
              </div>
            </div> */}
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">Email</label>
                <input type="email" className="form-control" placeholder="Enter user email" name="email" onChange={(e) => { handleCompanyUserChange(e) }}
                  value={companyUser.email}
                />
                {errors.email && <div className="text-danger">{errors.email}</div>}
              </div>
            </div>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">Phone</label>
                <PhoneInput
                  name="contact_no"
                  country={'in'}
                  value={companyUser.contact_no}
                  onChange={phone => handleCompanyUserContact({ phone })}
                />
                {errors.contact_no && <div className="text-danger">{errors.contact_no}</div>}

              </div>
            </div>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">WhatsApp</label>
                <PhoneInput
                  name="whats_app_number"
                  country={'in'}
                  value={companyUser.whats_app_number}
                  onChange={phone => handleCompanyUserWhatsApp({ phone })}
                />
                {errors.whats_app_number && <div className="text-danger">{errors.whats_app_number}</div>}

              </div>
            </div>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">Permission for</label>
                <Select
                  name='module_id'
                  options={purchasedModules}
                  isMulti="true"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#ddddff',
                      primary: '#6161ff',
                    },
                  })}
                  value={selectedModules}
                  onChange={handleCompanyUserPermission}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">Upload image</label>
                <input type="file" className="form-control" name="userProfileImage" onChange={(e) => { setSelectedFile(e.target.files[0], 'e.target') }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-success' onClick={(e) => { handleSubmit(e) }}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
      {/* Create User modal end */}
      {/* View User modal start */}
      <Modal
        show={viewUserShow}
        onHide={viewUserModalClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton className="gth-blue-light-bg">
          <Modal.Title className="gth-modal-title">{updateCompanyUser?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pb-1'>
          <div className='row'>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">User name</label>
                <p>{updateCompanyUser?.name}</p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">Role</label>
                <p>{updateCompanyUser?.role?.role}</p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">Email</label>
                <p>{updateCompanyUser?.email}</p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">Phone</label>
                <p>{updateCompanyUser?.contact_no}</p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">WhatsApp</label>
                <p>{updateCompanyUser?.whats_app_number}</p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">Permission for</label>
                <p>
                  {updateCompanyUser?.User_With_Module?.map((user) => (
                    <span className="badge rounded-pill exp-badge-primary-light me-2 mb-1">{user.module.module_name}</span>
                  ))}
                </p>
                {/* <span className="badge rounded-pill exp-badge-primary-light me-2 mb-1">Inventory Management</span>
                  <span className="badge rounded-pill exp-badge-primary-light me-2 mb-1">BMS</span>
                  <span className="badge rounded-pill exp-badge-primary-light me-2 mb-1">Production Management</span>
                  <span className="badge rounded-pill exp-badge-primary-light me-2 mb-1">Project Management</span>
                  <span className="badge rounded-pill exp-badge-primary-light me-2 mb-1">HR</span>
                  <span className="badge rounded-pill exp-badge-primary-light me-2 mb-1">Purchase Management</span> */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* View User modal end */}
      {/* Edit User modal start */}
      <Modal
        show={editUserShow}
        onHide={() => { setUpdateCompanyUser({}); editUserModalClose(); }}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton className="gth-blue-light-bg">
          <Modal.Title className="gth-modal-title">Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pb-1'>
          <div className='row'>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">User name</label>
                <input type="text" className="form-control" placeholder="Enter user name" name="name" onChange={(e) => { handleUpdateChange(e) }} value={updateCompanyUser.name} />
              </div>
            </div>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">password</label>
                <input type="password" className="form-control" placeholder="Enter user password" name="password" onChange={(e) => { handleUpdateChange(e) }}
                  value={updateCompanyUser.password}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">Email</label>
                <input type="email" className="form-control" placeholder="Enter user email" name="email" onChange={(e) => { handleUpdateChange(e) }} value={updateCompanyUser.email} />
              </div>
            </div>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">Phone</label>
                <PhoneInput
                  country={'in'}
                  value={updateCompanyUser.contact_no}
                  onChange={phone => handleUpdateCompanyUserContact({ phone })}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">WhatsApp</label>
                <PhoneInput
                  country={'in'}
                  value={updateCompanyUser.whats_app_number}
                  onChange={phone => handleUpdateCompanyUserWhatsApp({ phone })}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">Permission for</label>
                <Select
                  name='module_id'
                  options={purchasedModules.filter((purMod) => !userWithModule?.some((useMod) => useMod.value === purMod.value))}
                  value={userWithModule}
                  isMulti="true"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#ddddff',
                      primary: '#6161ff',
                    },
                  })}
                  onChange={handleCompanyUserPermissionUpdate}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className="form-group">
                <label className="form-label">Upload image</label>
                <input type="file" className="form-control" name="userProfileImage" onChange={(e) => { setSelectedFile(e.target.files[0]) }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-success' onClick={(e) => { handleUserUpdate(e) }}>
            Update
          </button>
        </Modal.Footer>
      </Modal>
      {/* Edit User modal end */}
      {/* Profile image modal start */}
      <Modal
        show={profileImageModalShow}
        onHide={handleProfileImageModalClose}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Modal.Header closeButton className="gth-blue-light-bg">
          <Modal.Title className="gth-modal-title">Profile Image </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-md-12'>
              <AvatarEditor
                ref={editorRef}
                image={showImage}
                width={200}
                height={200}
                border={40}
                borderRadius={150}
                color={[0, 0, 0, 0.72]}
                scale={scale}
                className="avtar-editor-wrap"
              />
              <div className="form-group mt-3">
                <label htmlFor="upload" className="form-label">Upload Image:</label>
                <input
                  type="file"
                  className="form-control"
                  id="upload"
                  onChange={(e) => { setImage(e.target.files[0]); setShowImage(URL.createObjectURL(e.target.files[0])) }}
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="scale" className="form-label">Scale:</label>
                <input
                  type="range"
                  className="form-control-range"
                  id="scale"
                  value={scale}
                  min="1"
                  max="2"
                  step="0.01"
                  onChange={handleScaleChange}
                />
              </div>
              <div className="mt-3 d-flex justify-content-end">
                <button className="btn btn-success" onClick={handleSave}>Crop</button>
              </div>

            </div>

          </div>
        </Modal.Body>

      </Modal>
      {/* Profile image modal end */}
    </>
  )
}

export default UserDashboardIndex


