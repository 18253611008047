import React, { useState,useEffect } from 'react';
import "./cart.min.css";
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import LnModule from '../LandingModule/LnModule';
import { useSelector, useDispatch } from 'react-redux';
import { removeAddonModule } from '../../Redux/slices/addonModulesSlice';
import { Link, useNavigate } from 'react-router-dom';
import { orderPost } from '../../services/User/api';
import { placeOrderStart, placeOrderSuccess, placeOrderFailure } from '../../Redux/slices/orderSlice';
import countryCodeCurrencyMap from '../../services/utils/countryCurrencyMap.json';
import currencySymbolMap from '../../services/utils/currencySymbol.json';
import axios from 'axios';

const countryInfo = JSON.parse(localStorage.getItem('countryInfo'));


const CurrencyConverter = ({ amount }) => {
  const [finalAmount, setFinalAmount] = useState(amount);
  const [convertedAmount, setConvertedAmount] = useState(null);

  const url = `https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=inr,${countryCodeCurrencyMap[countryInfo?.countryCode]}`;
  useEffect(() => {
      const fetchConversionRate = async () => {
          try {
              const response = await axios.get(url);
              const baseCurrency = Number(response.data.bitcoin.inr);
              const currencyToConvert = Number(response.data.bitcoin[`${countryCodeCurrencyMap[countryInfo?.countryCode].toLowerCase()}`]);
              const convertedCurrency = Number(finalAmount) * currencyToConvert / baseCurrency;
              setConvertedAmount(Math.ceil(convertedCurrency));
          } catch (error) {

          }
      };

      if (finalAmount > 0) {
          fetchConversionRate();
      }
  },[finalAmount]);

  return (
      <span className="">
          {currencySymbolMap[countryInfo?.countryCode]}<span>
              {convertedAmount ? convertedAmount.toFixed(2) : finalAmount}
          </span>
      </span>
  );
};

const DollarCurrencyConverter = ({ amount }) => {
  const [finalAmount, setFinalAmount] = useState(amount);
  const [convertedAmount, setConvertedAmount] = useState(null);

  const url = `https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=inr,usd`;
  useEffect(() => {
      const fetchConversionRate = async () => {
          try {
              const response = await axios.get(url);
              const baseCurrency = Number(response.data.bitcoin.inr);
              const currencyToConvert = Number(response.data.bitcoin.usd);
              const convertedCurrency = Number(finalAmount) * currencyToConvert / baseCurrency;
              console.log(convertedCurrency,response,'convertedCurrencyDollar')
              setConvertedAmount(Math.ceil(convertedCurrency));
          } catch (error) {
              console.log('Error',error.message);
          }
      };

      if (finalAmount > 0) {
          fetchConversionRate();
      }
  },[finalAmount]);

  return (
      <span className="">
          {currencySymbolMap['US']}<span>
              {convertedAmount ? convertedAmount.toFixed(2) : finalAmount}
          </span>
      </span>
  );
};


function CartPage(props) {
  const { skipOrGetStart, planDetails } = props;
  const navigate = useNavigate();
 
  console.log("skipOrGetStart", skipOrGetStart.action);
  const selectedPlan = useSelector((state) => state.selectedPlan);
  // console.log(selectedPlan,'selectedPlan');
 // console.log(selectedPlan.plan.id, "plandetails");
  const addonModules = useSelector((state) => state.addonModules);
  const skipOrGet = useSelector((state) => state.skipGet);


  // console.log("skipOrGet", skipOrGet);
  // console.log("selectedPlan", selectedPlan);

  const dispatch = useDispatch();

  const [addonRemoveShow, setAddonRemoveShow] = useState(false);
  const [selectedAddon, setSelectedAddon] = useState(null);

  const hideRemoveAddonModal = () => setAddonRemoveShow(false);
  const showRemoveAddonModal = (addon) => {
    setSelectedAddon(addon);
    setAddonRemoveShow(true);
  };

  const handleRemoveAddon = () => {
    if (selectedAddon) {
      dispatch(removeAddonModule({ id: selectedAddon.id }));
    }
    hideRemoveAddonModal();
  };

  const [addMoreModuleModalShow, setAddMoreModuleModalShow] = useState(false);

  const hideAddModuleModal = () => setAddMoreModuleModalShow(false);
  const showAddonModuleModal = () => setAddMoreModuleModalShow(true);
  const handleOrderNow = async () => {
    // if (token) {
    //   try {
    //     dispatch(placeOrderStart()); // Dispatch action to start placing order
    //     const response = await orderPost(planDetails.plan.id, token);
    //     console.log("Order response:", response);
    //     dispatch(placeOrderSuccess(response)); // Dispatch action on successful order placement
    //     navigate("/purchase"); // Redirect to order success page or appropriate page
    //   } catch (error) {
    //     console.error("Failed to place order:", error.message);
    //     dispatch(placeOrderFailure(error.message)); // Dispatch action on order failure
    //   }
    // }
  };

  return (
    <>
      {skipOrGet.action === "getStarted" ? (
        <>
          <section className="ln-cart-sec position-relative">
            <div className="container">
              {
                selectedPlan && (
                  <div className="row">
                    <div className="col-md-6">
                      <h4 className="mb-2">Selected Plan</h4>
                      <div className="selected-plan mb-4">
                        <div className="select-plan-header">
                          <h1 className="plan-name">{selectedPlan?.plan.plan_name}</h1>
                          <div className="select-plan-price">
                            <span>{(selectedPlan.planType === "Monthly") ? (<CurrencyConverter amount={selectedPlan?.plan.monthly_price }/>): <CurrencyConverter amount={selectedPlan?.plan.annual_price}/>}</span>/{(selectedPlan.planType === "Monthly") ? 'month' : 'year'}
                          </div>
                          <div>
                            {`${(selectedPlan.planType === "Monthly") ? (`Monthly`) : (`Annual`)} Subscription`}<br />
                          </div>
                        </div>

                        <div className="select-plan-details-wrap">
                          <ul>
                            {selectedPlan?.plan?.description.split(',').map((feature, index) => (
                              <li key={index}>
                                <i className="fal fa-check" /> {feature}
                              </li>
                            ))}
                          </ul>
                          <div>
                            <p className="mb-1 notes">
                              <mark className='bg-transparent text-danger'>**</mark> GST charges are included.
                            </p>
                            <p className="mb-1 notes">
                              <mark className='bg-transparent text-danger'>#</mark>Whatsapp API cost charge not included.
                            </p>
                            <p className="notes">
                              <mark className="bg-transparent text-dark">*</mark>
                              Storage is limited with plans. For additional storage contact support at <a href="mailto:crm@growthh.com" className="gth-text-primary1">crm@growthh.com</a>
                            </p>
                          </div>

                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <h4 className="mb-0">Addon Module</h4>
                        <label className="mb-0 gth-text-primary1 cursor-pointer" onClick={showAddonModuleModal}><i className="fi fi-br-plus me-2"></i>Add More</label>
                      </div>

                      <div className="addon-module-wrapper">
                        {addonModules && addonModules.addonModules.map((addOns) => (
                          <div className="addon-module-item" key={addOns.id}>
                            <span className="module-icon"><i className="fi fi-sr-crm-computer"></i></span>
                            <div className="ps-3">
                              <span className="module-name">{addOns?.plan_name}</span>
                              <p className="cart-short-desc">
                                {addOns?.description}
                              </p>
                            </div>

                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip>
                                  Remove
                                </Tooltip>
                              }
                            >
                              <button className="module-selected" onClick={() => showRemoveAddonModal(addOns)}><i className="fi fi-sr-circle-xmark"></i></button>
                            </OverlayTrigger>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="plan-details-wrap">
                        <h4 className="mb-2">Plan Details</h4>
                        <div className="card plan-details-card">
                          <div className="card-body p-4">
                            <table className="custom-table">
                              <tr>
                                <th>
                                  <span className="text-muted"><u>Plan</u></span><br />
                                  {selectedPlan?.plan.plan_name}
                                </th>
                                <th>
                                  <span className=""> <span>{(selectedPlan.planType === "Monthly")? <DollarCurrencyConverter amount={selectedPlan?.plan.monthly_price} /> : <DollarCurrencyConverter amount={selectedPlan?.plan.annual_price} />}</span></span>
                                </th>
                              </tr>
                              <tr>
                                <th>
                                  <span className="text-muted"><u>Addon Module</u></span><br />
                                  <div className='d-flex flex-column'>
                                    {addonModules?.addonModules.map((item) => (
                                      <span key={item.id}>{item?.plan_name}<br /></span>
                                    ))}
                                  </div>

                                </th>
                                <th className='d-flex flex-column'>
                                  {addonModules?.addonModules.map((item) => (
                                    <span key={item.id} className=""><span>{(selectedPlan.planType === "Monthly")? <DollarCurrencyConverter amount={item?.monthly_price} /> : <DollarCurrencyConverter amount={item?.annual_price} />}</span></span>
                                  ))}
                                </th>
                              </tr>
                              <tr className="border-bottom tbl-foot">
                                <th>
                                  <span className="text-dark total">Total</span>
                                </th>
                                <th>
                                  {(selectedPlan.planType === "Monthly")
                                    ? (
                                      <DollarCurrencyConverter amount={selectedPlan.plan.monthly_price +
                                      addonModules.addonModules.reduce((total, addon) => total + addon.monthly_price, 0)}/>
                                    )
                                    : (
                                      <DollarCurrencyConverter amount={selectedPlan.plan.annual_price +
                                      addonModules.addonModules.reduce((total, addon) => total + addon.annual_price, 0)}/>
                                    )
                                  }
                                </th>
                              </tr>
                            </table>
                            <p className="my-3"><small>Next charge date: 24 June, 2025</small></p>
                            <div className="text-center mt-3">
                              {planDetails ? (
                                <>
                                  <button className="gtn-warning-btn text-uppercase" onClick={handleOrderNow}>Order Now</button>
                                </>
                              ) : (<>
                                <Link to="/email-registration" className="gtn-warning-btn text-uppercase">Confirm</Link>
                              </>)}

                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                )
              }
              
            </div>
          </section>
        </>
      ) : (
        <>
        {
          selectedPlan && (
                <section className="ln-cart-sec position-relative">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6">
                        <h4 className="mb-2">Selected Plan</h4>
                        <div className="selected-plan mb-4">
                          <div className="select-plan-header">
                            <h1 className="plan-name">{selectedPlan?.plan?.plan_name}</h1>
                            <div className="select-plan-price">
                              {/* <span className=""><span>{selectedPlan?.toggle ? selectedPlan?.plan?.monthly_price : selectedPlan?.plan?.annual_price}</span></span> */}
                            </div>
                            <div>
                              {selectedPlan?.toggle ? 'Monthly' : "Yearly"} Subscription<br />
                              Renews <span>June 16, 2024</span>
                            </div>
                          </div>

                          <div className="select-plan-details-wrap">
                            <ul>
                              {selectedPlan?.plan?.description.split(',\n').map((feature, index) => (
                                <li key={index}>
                                  <i className="fal fa-check" /> {feature}
                                </li>
                              ))}
                            </ul>
                            <div>
                              <p className="mb-1 notes">
                                <mark className='bg-transparent text-danger'>**</mark> GST charges are included.
                              </p>
                              <p className="mb-1 notes">
                                <mark className='bg-transparent text-danger'>#</mark>Whatsapp API cost charge not included.
                              </p>
                              <p className="notes">
                                <mark className="bg-transparent text-dark">*</mark>
                                Storage is limited with plans. For additional storage contact support at <a href="mailto:crm@growthh.com" className="gth-text-primary1">crm@growthh.com</a>
                              </p>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="plan-details-wrap">
                          <h4 className="mb-2">Plan Details</h4>
                          <div className="card plan-details-card">
                            <div className="card-body p-4">
                              <table className="custom-table">
                                <tr>
                                  <th>
                                    <span className="text-muted"><u>Plan</u></span><br />
                                    {selectedPlan?.plan?.plan_name}
                                  </th>
                                  <th>
                                    <span className="">&#8377; <span>{selectedPlan?.toggle ? selectedPlan?.plan?.monthly_price : selectedPlan?.plan?.annual_price}</span></span>
                                  </th>
                                </tr>
                                <tr className="border-bottom tbl-foot">
                                  <th>
                                    <span className="text-dark total">Total</span>
                                  </th>
                                  <th>
                                    <span className="">&#8377; <span>{selectedPlan?.toggle ? selectedPlan?.plan?.monthly_price : selectedPlan?.plan?.annual_price}</span></span>
                                  </th>
                                </tr>
                              </table>
                              <p className="my-3"><small>Next charge date: 24 June, 2025</small></p>
                              <div className="text-center mt-3">
                                {planDetails ? (
                                  <>
                                    <button className="gtn-warning-btn text-uppercase" onClick={handleOrderNow}>Order now</button>
                                  </>
                                ) : (<>
                                  <Link to="/login" className="gtn-warning-btn text-uppercase">Confirm</Link>
                                </>)}
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </section>
          )
        }
          
        </>
      )}

      {/* module remove modal */}
      <Modal show={addonRemoveShow} onHide={hideRemoveAddonModal} backdrop="static" keyboard={false} centered>
        <Modal.Body className="bg-warning-light rounded-top-left-10 rounded-top-right-10">
          <div className="delete-warning-wrap text-center">
            <div className="delete-warning-icon mb-0 mt-2">
              <i className="fi fi-sr-triangle-warning text-warning"></i>
            </div>
            <h4 className="text-dark mb-2">Are you sure?</h4>
            <p className="text-muted">Do you really want to remove this module? <br />This process cannot be undone.</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button onClick={hideRemoveAddonModal} className="btn btn-secondary">
            Close
          </button>
          <button onClick={handleRemoveAddon} className="btn btn-exp-red">
            Remove
          </button>
        </Modal.Footer>
      </Modal>
      {/* module remove modal end */}
      {/* module add modal start */}
      <Modal id="moduleAddon" show={addMoreModuleModalShow} onHide={hideAddModuleModal} backdrop="static" keyboard={false} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Modules</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='module_page_small'>
            <LnModule addOns={addonModules} />
          </div>
          <div className="text-center mt-3">
            <button className="gtn-success-btn text-uppercase" onClick={hideAddModuleModal}>
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CartPage;
